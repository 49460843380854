import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';
import { useIntervalNow } from '~/hooks/useIntervalNow';
/**
 * # 專接 TradeInfo
 *
 * @example
 *   useSignalrBuild({
 *     listenOnSignalrEvent: SignalrEvent.TradeInfo,
 *     reconnectCheckInterval: 15000,
 *     valueBuilder: data => {
 *       useSignalrStoreValueTradeInfo_proxy.valueBuilder(data)
 *     },
 *   })
 *
 *   useSignalrStoreValueTradeInfo_proxy.useValueUpdateInterval(1000)
 *
 *   const value = useSnapshot(useSignalrStoreValueTradeInfo_proxy).value
 */
const proxyStore = proxy({
    /**
     * # 所有值
     *
     * ## 需要搭配 useSignalrStoreValueTradeInfo_proxy.useValueUpdateInterval(1000)
     */
    value: {},
    /** # 內部接值用的，如需優化刷新速度時，可使用 */
    valueRef: {},
    /** ## 搭配 useSignalrBuild 使用 */
    valueBuilder: ((data) => {
        if ('symbol' in data && 'filled_buy_count' in data && 'filled_sell_count' in data) {
            proxyStore.valueRef[data.symbol] = { ...data };
        }
    }),
    /** # 使用 `.valueRef` 來刷新 `.value` 之間隔時間 */
    useValueUpdateInterval: (intervalMillisecond) => {
        useIntervalNow(() => {
            proxyStore.value = { ...proxyStore.valueRef };
        }, intervalMillisecond);
    },
});
export const useSignalrStoreValueTradeInfo_proxy = proxyStore;
devtools(useSignalrStoreValueTradeInfo_proxy, { name: 'useSignalrStoreValueTradeInfo_proxy' });
